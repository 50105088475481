import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { convertButtonColor, reverseColors } from '@utils/ctaUtils';
import { extractColors, formatHttpsUrl } from '@utils/utils';
import { runCommonValidation } from 'pubweb-smokey/dist/utils/validation';
import Colors from 'pubweb-smokey/dist/colors.js';
import Form from '@components/Shared/Form/Form';
import LoadingIndicator from '@components/Shared/LoadingIndicator/LoadingIndicator';
import { LocationsContext } from '@contexts/LocationsContext';
import { AnkleCTAStyles } from './AnkleCTA.styled';
import Button from '@components/Shared/Buttons/Button';
import LocationTextBox from '../../Shared/LocationSelector/LocationTextBox';
import * as locationService from '@services/locationService';
import { buildQueryString } from 'pubweb-smokey/dist/utils/utils';
import {
  bathItems,
  bedItems,
  allPriceChoicesMin,
  allPriceChoicesMax,
} from '@utils/homeSearchConstants';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import { SEARCH_DEFAULT_BATHS, SEARCH_DEFAULT_BEDS } from '@utils/config';

const DropDownList = dynamic(
  () => import('@components/Shared/DropDownList/DropDownList'),
  {
    ssr: true,
  }
);

const AnkleCTA = ({ ctaData }) => {
  const locationsContext = useContext(LocationsContext);
  const router = useRouter();
  const buttonColors = extractColors(ctaData.buttonAndTextColors);
  const minPrice = 75000;
  const maxPrice = 150000;

  const [filterBeds, setFilterBeds] = useState(SEARCH_DEFAULT_BEDS);
  const [filterBaths, setFilterBaths] = useState(SEARCH_DEFAULT_BATHS);
  const [filterMinPrice, setFilterMinPrice] = useState(minPrice);
  const [filterMaxPrice, setFilterMaxPrice] = useState(maxPrice);
  const [priceChoicesMin, setPriceChoicesMin] = useState(allPriceChoicesMin);
  const [priceChoicesMax, setPriceChoicesMax] = useState(allPriceChoicesMax);
  const [formErrors, setFormErrors] = useState({});
  const [newPostalCode, setNewPostalCode] = useState(
    locationsContext.state?.geocode?.postalCode
  );
  const [oldPostalCode, setOldPostalCode] = useState(
    locationsContext.state?.geocode?.postalCode
  );
  const [submittingForm, setSubmittingForm] = useState(false);

  const createLocLink = (newGeolocation) => {
    return (
      newGeolocation.city +
      '--' +
      newGeolocation.state +
      '--' +
      newGeolocation.postalCode
    );
  };

  useEffect(() => {
    setPriceChoicesMax(
      allPriceChoicesMax.filter(
        (priceChoice) => priceChoice.value > filterMinPrice
      )
    );
    setPriceChoicesMin(
      allPriceChoicesMin.filter(
        (priceChoice) => priceChoice.value < filterMaxPrice
      )
    );
  }, [filterMinPrice, filterMaxPrice]);

  const navigateToHomeSearch = () => {
    if (submittingForm) {
      return;
    }

    const formErrors = runCommonValidation({
      zip: { value: newPostalCode, touched: true },
    });
    setSubmittingForm(true);
    if (formErrors.hasErrors) {
      setFormErrors(formErrors);
      setSubmittingForm(false);
    } else {
      const query = buildQueryString({
        beds: filterBeds,
        baths: filterBaths,
        minPrice: filterMinPrice,
        maxPrice: filterMaxPrice,
      });
      locationService
        .getLocationByIpOrPostalCode(newPostalCode)
        .then((result) => {
          setOldPostalCode(newPostalCode);
          const targetUrl =
            ctaData.firstCtaButtonUrl + createLocLink(result) + '/' + query;
          router.push(targetUrl);
        })
        .catch((err) => {
          if (err?.response?.status == 400) {
            locationService
              .getLocationByIpOrPostalCode(oldPostalCode)
              .then((result) => {
                setNewPostalCode(oldPostalCode);
                const targetUrl =
                  ctaData.firstCtaButtonUrl +
                  createLocLink(result) +
                  '/' +
                  query;
                router.push(targetUrl);
              });
          }
        });
    }
  };

  const onPostalCodeChange = (value) => {
    setNewPostalCode(value);
  };

  const getContentAlignment = (contentfulString) => {
    let contentAlignment;
    switch (contentfulString) {
      case 'Right Align':
        contentAlignment = 'flex-end';
        break;
      case 'Left Align':
        contentAlignment = 'flex-start';
        break;
      case 'Center Align':
        contentAlignment = 'center';
        break;
      default:
        contentAlignment = 'flex-start';
    }
    return contentAlignment;
  };

  return (
    <AnkleCTAStyles
      $submittingForm={submittingForm}
      align={getContentAlignment(ctaData?.alignCtaContent)}
    >
      <LocationsContext.Consumer>
        {({ state }) => {
          return (
            <>
              <div
                className={`ankle-cta-image relative flex h-full w-full overflow-hidden pt-[50%]`}
              >
                <Image
                  alt={ctaData.ctaImage.description || ctaData.ctaImage.title}
                  src={formatHttpsUrl(
                    `${ctaData.ctaImage.file.url}?q=85&w=1800&fm=webp`
                  )}
                  fill
                />
              </div>
              <div className="ankle-cta-content-container">
                <div className="ankle-cta-content">
                  <h4 className="ankle-searchcard-header">
                    {ctaData.ctaHeader}
                  </h4>

                  <Form
                    className="ankle-searchcard-form"
                    onSubmit={navigateToHomeSearch}
                  >
                    <div className="ankle-searchcard-field">
                      <LocationTextBox
                        locationKey="ankle-searchcard-field-zipcode"
                        postalCode={state.postalCode}
                        formErrors={formErrors}
                        onPostalCodeChange={onPostalCodeChange}
                        textFieldStyle="outlined"
                        focusOnLoad={false}
                      />
                    </div>

                    <div className="ankle-searchcard-field-price">
                      <DropDownList
                        className="ankle-searchcard-field min-price"
                        id="ankle-searchcard-field-minprice"
                        dropDownStyle="outlined"
                        label="Min Price"
                        onChange={(value) => {
                          setFilterMinPrice(String(value.value));
                          pushGTMEvent(
                            'ch.findDreamHomePriceClick',
                            'footer_search_price',
                            {
                              location: 'Homepage',
                              category: 'home_search',
                              action: 'form',
                              label: 'ch.findDreamHomePriceClick',
                            }
                          );
                        }}
                        items={priceChoicesMin}
                        selectedValue={filterMinPrice}
                        defaultValue={filterMinPrice}
                        automationId="ankle-searchcard-field-minprice"
                      ></DropDownList>
                    </div>
                    <div className="ankle-searchcard-field-price">
                      <DropDownList
                        className="ankle-searchcard-field max-price"
                        id="ankle-searchcard-field-maxprice"
                        dropDownStyle="outlined"
                        label="Max Price"
                        onChange={(value) => {
                          setFilterMaxPrice(String(value.value));
                          pushGTMEvent(
                            'ch.findDreamHomePriceClick',
                            'footer_search_price',
                            {
                              location: 'Homepage',
                              category: 'home_search',
                              action: 'form',
                              label: 'ch.findDreamHomePriceClick',
                            }
                          );
                        }}
                        items={priceChoicesMax}
                        selectedValue={filterMaxPrice}
                        defaultValue={filterMaxPrice}
                        automationId="ankle-searchcard-field-maxprice"
                      ></DropDownList>
                    </div>

                    <DropDownList
                      className="ankle-searchcard-field"
                      id="ankle-searchcard-field-bed"
                      dropDownStyle="outlined"
                      label="Beds"
                      items={bedItems}
                      selectedValue={String(filterBeds)}
                      onChange={(value) => {
                        setFilterBeds(value.value);
                        pushGTMEvent(
                          'ch.findDreamHomeBedsClick',
                          'footer_search_beds',
                          {
                            location: 'Homepage',
                            category: 'home_search',
                            action: 'form',
                            label: 'ch.findDreamHomeBedsClick',
                          }
                        );
                      }}
                      automationId="ankle-searchcard-field-bed"
                    ></DropDownList>
                    <DropDownList
                      className="ankle-searchcard-field"
                      id="ankle-searchcard-field-bath"
                      dropDownStyle="outlined"
                      label="Baths"
                      items={bathItems}
                      selectedValue={String(filterBaths)}
                      onChange={(value) => {
                        setFilterBaths(value.value);
                        pushGTMEvent(
                          'ch.findDreamHomeBathClick',
                          'footer_search_bath',
                          {
                            location: 'Homepage',
                            category: 'home_search',
                            action: 'form',
                            label: 'ch.findDreamHomeBathClick',
                          }
                        );
                      }}
                      automationId="ankle-searchcard-field-bath"
                    ></DropDownList>

                    <Button
                      primaryColor={
                        buttonColors && convertButtonColor(buttonColors)
                      }
                      buttonStyle={
                        buttonColors &&
                        convertButtonColor(buttonColors) ===
                          Colors.primary.white
                          ? 'outlined'
                          : null
                      }
                      onDarkBg={buttonColors && reverseColors(buttonColors)}
                      fillContainer={false}
                      className={`gtm-home-listings ${
                        submittingForm ? 'submitting' : ''
                      }`}
                      onClick={() =>
                        pushGTMEvent(
                          'ch.findDreamHomeSubmitSearchClick',
                          'footer_search_submit',
                          {
                            location: 'Homepage',
                            category: 'home_search',
                            action: 'form',
                            label: 'ch.findDreamHomeSubmitSearchClick',
                          }
                        )
                      }
                      automationId="cta-button-search-homes"
                    >
                      <span className="webform-button">
                        {ctaData.firstCtaButtonText}
                      </span>
                      {submittingForm ? (
                        <LoadingIndicator
                          color={Colors.primary.white.standard}
                          width="24"
                          height="24"
                        />
                      ) : (
                        <> </>
                      )}
                    </Button>
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </LocationsContext.Consumer>
    </AnkleCTAStyles>
  );
};

AnkleCTA.propTypes = {
  ctaData: PropTypes.any.isRequired,
};

export default AnkleCTA;
